import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

import cce from "../images/nickelled-cce.jpg"

export default function About() {
  return (
    <Layout>
      <SEO title="About Us" />
      <PageTitle
        preheader=""
        title="About Nickelled"
        subtitle="We're a small business that does things differently."
      ></PageTitle>
      <div class="relative bg-white py-16 sm:py-24">
        <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div class="relative sm:py-16 lg:py-0">
            <div
              aria-hidden="true"
              class="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div class="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
              <svg
                class="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width="404"
                height="392"
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x="0"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width="404"
                  height="392"
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div>
            <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              <div class="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  class="absolute inset-0 h-full w-full object-cover"
                  src={cce}
                  alt=""
                />
                <div class="absolute inset-0 bg-gradient-to-t from-oOrange via-oOrange opacity-90"></div>
                <div class="relative px-8">
                  <blockquote class="mt-8">
                    <div class="relative text-lg font-medium text-white md:flex-grow">
                      <svg
                        class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-oOrange-light"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p class="relative">
                        Helping customers by building great products, and
                        growing our business in a sustainable way that works for
                        our team. That's the Nickelled way.
                      </p>
                    </div>

                    <footer class="mt-4">
                      <p class="text-base font-semibold text-oOrange-light">
                        David Batey, CEO
                      </p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            <div class="pt-12 sm:pt-16 lg:pt-20">
              <h2 class="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                Small Business, Big Outcomes
              </h2>
              <div class="mt-6 text-gray-500 space-y-6">
                <p class="text-lg">
                  Founded in London in 2014, we’ve grown to serve a global
                  customer base from offices in the UK, US and Singapore.
                </p>
                <p class="text-lg">
                  Our founders have years of consulting experience, where we
                  watched companies build great products and then fail to
                  educate customers on how to use them. Ever since, Nickelled
                  has had three fundamental principles at its core.
                </p>
                <p class="text-lg text-bold">
                  #1 We're Passionate about technology
                </p>
                <p class="text-base leading-7">
                  We could have built a walkthrough solution that’s the same as
                  our competitors. We didn’t.
                </p>
                <p class="text-base leading-7">
                  We believe technology should be used to solve tricky problems,
                  and our walkthroughs are more stable, more versatile and more
                  technologically advanced than anyone else on the market.
                </p>
                <p class="text-lg text-bold">#2 We're Passionate about SaaS</p>
                <p class="text-base leading-7">
                  We believe that providing a better customer experience helps
                  to build great businesses by increasing conversions,
                  increasing average customer value and reducing churn.
                </p>
                <p class="text-base leading-7">
                  And we’ve got the figures to prove it, having helped companies
                  from around the world grow their business through better
                  onboarding.
                </p>
                <p class="text-lg text-bold">
                  #3 We're Passionate about Learning
                </p>
                <p class="text-base leading-7">
                  We’re a bootstrapped company because, for us, the journey is
                  the destination.
                </p>
                <p class="text-base leading-7">
                  We come to work every day to create great products and
                  services, and by doing this, we’re learning new ways to solve
                  problems and create value for our customers every day. And if
                  you’re a curious kind of person who enjoys experimenting, we’d
                  love to talk to you too – find out more about joining the team
                  here.
                </p>
              </div>
            </div>

            <div class="mt-10">
              <dl class="grid grid-cols-2 gap-x-4 gap-y-8">
                <div class="border-t-2 border-gray-100 pt-6">
                  <dt class="text-base font-medium text-gray-500">Founded</dt>
                  <dd class="text-3xl font-extrabold tracking-tight text-gray-900">
                    2014
                  </dd>
                </div>

                <div class="border-t-2 border-gray-100 pt-6">
                  <dt class="text-base font-medium text-gray-500">Team Size</dt>
                  <dd class="text-3xl font-extrabold tracking-tight text-gray-900">
                    9
                  </dd>
                </div>
              </dl>
            </div>
            <div class="mt-10">
              <div class="w-full border-t-2 border-gray-100 pt-6">
                <dt class="text-base font-medium text-gray-500">
                  United Kingdom
                </dt>
                <dd class="text-base leading-7">
                  <p>
                    {" "}
                    Nickelled by Omniplex Learning, <br /> 25-26 Thrales End
                    Farm and Business Centre <br /> Thrales End Lane <br />{" "}
                    Harpenden, Hertfordshire <br /> AL5 3NS <br /> United
                    Kingdom{" "}
                  </p>
                  <p>+44 8000 850 550</p>
                </dd>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
